<template>
<v-app>
    <section class="tables">
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="card-title">Liste des plannings</div>
              <nav aria-label="breadcrumb" class="float-right">
                <ol class="breadcrumb">
                  <router-link to="/addmarketing">
                    <li class="breadcrumb-item btn btn-primary">
                      Affecter un planning
                    </li>
                  </router-link>
                </ol>
              </nav>
              <div class="table-responsive">
                <input
                  value=""
                  class="form-control search-input"
                  placeholder="Recherche par nom "
                  type="text"
                  v-model="search"
                />
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Référence </th>
                      <th scope="col">Nom animatrice</th>
                      <th scope="col">Date debut</th>
                      <!-- <th scope="col">Date fin</th> -->
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(marketing, index) in searchFunction" :key="index">
                      <td>{{ marketing.nom }} </td>
                      <td>
                        <b v-if="marketing.user_id">{{ marketing.user_id["name"] }} </b>
                      </td>
                      
                  

                      <!-- <td>{{ marketing.produit_id }}</td> -->
                     
                      <td>{{ marketing.date_debut | formatDate }}</td>
                      <!-- <td>{{ marketing.date_fin | formatDate }}</td> -->

                       <td >
                        
                    <router-link 
                        :to="{ name: 'updatemarketing', params: { id: marketing._id } }"
                        ><i 
                        class="mdi mdi-pen icone text-info"></i></router-link
                      >  
                      <i 
                        class="mdi mdi-delete icone text-danger"  style="color:red"
                         @click="deletemarketing(marketing._id)"
                      />
                      
                          <i
                           v-if="marketing.user_id"
                          class="mdi mdi-eye icone text-primary"
                          title="Afficher les détails"
                          data-toggle="modal"
                          :data-target="'#test2' + marketing._id"
                        >
                        </i>
                         <!-- Modal 2 -->
                          

                        <div
                        
                          class="modal"
                          :id="'test2' + marketing._id"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div  class="modal-dialog" role="document">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5></h5>
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div> 
                              <div  class="modal-body">
                               <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Nom client
          </th>
           <th class="text-left">
            Produits
          </th>
          <th class="text-left">
            Date
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in marketing.eventscalander"
          :key="item.name"
        >
          <td>{{ item.Location }}</td>
           <td>
            <li v-for="(produit, index) in  item.Produits" :key="index">
                  {{ produit }}
                </li>
           </td>
          <td>{{ item.StartTime
 | formatDate }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
                          </div>
                          </div>
                          </div>
                        </div>
                         <i 
                        class="mdi mdi-backup-restore icone"  style="color:green;margin-left:5px"
                        @click.prevent="showPost(marketing._id)"
                       
                      />
                       <!-- Modal 3 -->
                           <input v-model="date_debut" style="margin-left:10px" v-if="showMessage==marketing._id" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 animate__animated animate__zoomIn" id="grid-last-name" type="date" placeholder="raison..">
                        
                           <i 
                            v-if="showMessage==marketing._id"
                       class="mdi mdi-send icone"  style="color:blue;margin-left:15px"
                        @click.prevent="renover_plannig(marketing._id)"
                       
                      />
                       
                      </td>
                 
                    
                    </tr>
                  </tbody>
                </table>
                   
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</v-app>
   
</template>


<script>
  import { HTTP } from "@/axios";
  import Swal from "sweetalert2";
  import Toast from "sweetalert2";
  import moment from 'moment'

  export default {
    name: "basicTables",
    data() {
        let d = new Date();
     let ddebut=(new Date(d.setDate(d.getDate() + (((1 + 7 - d.getDay()) % 7) || 7)))).toISOString().substr(0, 10);
      return {
        date_debut:ddebut,
        marketings: [],
        search: "",
        showMessage: "",
      };
    },
  
    created() {
      this.getmarketings();
    },
    computed: {
      searchFunction() {
        return this.marketings.filter((item) => {
          return item.nom.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
        });
      },
    },
    methods: {
       showPost(postId){
           if(this.showMessage==postId){
               this.showMessage=""
           }
      else {this.showMessage=postId};
    },
      getmarketings() {
        HTTP.get("marketings/getmarketings")
          .then((response) => {
            this.marketings = response.data;
            console.log(this.marketings);
          })
          .then((err) => console.log(err));
      },
      
      deletemarketing(id) {
        Swal.fire({
          title: "Êtes-vous sûrs ?",
          text: "Vous voulez supprimé l'marketing",
          icon: "warning",
          cancelButtonText: "Annuler",
          showCancelButton: true,
          confirmButtonColor: "#3e884f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, valider!",
        }).then((result) => {
          if (result.isConfirmed) {
          HTTP
              .delete("marketings/deletemarketing/" + id)
              .then((response) => {
  
                Toast.fire({
                  position: "top-center",
                  icon: "success",
                  title: "marketing supprimé",
                });
  
                this.getmarketings();
              });
          }
        });
      },
        renover_plannig(id) {
        Swal.fire({
          title: "Êtes-vous sûrs ?",
          text: "Rénover le plannig pour la date"+":"+this.date_debut,
          icon: "warning",
          cancelButtonText: "Annuler",
          showCancelButton: true,
          confirmButtonColor: "#3e884f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, valider!",
        }).then((result) => {
          if (result.isConfirmed) {
            var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            var dayName = days[new Date(this.date_debut).getDay()];
            if(dayName!=='Mon')
            {
                    {Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Il faut que le jour de début lundi!',
              footer: ''
            
            })}
            }
            else {
                  let marketing ={
            id:id,
            date:this.date_debut

          }
          HTTP
              .post("marketings/renovermarketing" ,marketing)
              .then((response) => {
  
                Toast.fire({
                  position: "top-center",
                  icon: "success",
                  title: "marketing rénovee",
                });

                this.getmarketings();
                 let d = new Date();
                  let ddebut=(new Date(d.setDate(d.getDate() + (((1 + 7 - d.getDay()) % 7) || 7)))).toISOString().substr(0, 10);
                this.date_debut=ddebut;
                this.showMessage="";
                
              });
            }
         
          }
        });
      },
    },
  };
</script>